export const ConfidentialityLevel = {
    strictly_confidential: 'Strictly Confidential',
    confidential: 'Confidential',
    internal: 'Internal use',
    public: 'Public',
};
export const Criticality = {
    critical: 'Critical',
    non_critical: 'Non critical',
};

export const CrawlerSchedule = {
    Weekly: '10 1 * * SUN *',
    Daily: '10 1 * * ? *',
    Hourly: '10 * * * ? *',
};
