import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Menu from '@material-ui/core/Menu';
import { Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { useGlobalContext, Notification } from '../GlobalContext';

export const Notifications = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const menuOpen = Boolean(anchorEl);
    const [selectedNotification, setSelectedNotification] = React.useState({
        id: '',
        title: '',
        description: '',
        content: '',
    });
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const { notifications, removeNotification, clearNotifications } = useGlobalContext();

    const handleOpenMenu = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };
    const handleClickMessage = (event: any, notification: Notification) => {
        setSelectedNotification(notification);
        setDialogOpen(true);
        handleCloseMenu();
    };
    const handleClearAll = () => {
        clearNotifications();
        setDialogOpen(false);
    };

    const handleCloseDialog = (event: any, notification: Notification) => {
        removeNotification(notification.id);
        setDialogOpen(false);
    };

    return (
        <React.Fragment>
            <IconButton aria-label="show notifications" color="inherit" onClick={handleOpenMenu}>
                <Badge badgeContent={notifications.length} color="secondary">
                    <NotificationsIcon />
                </Badge>
            </IconButton>
            {notifications.length > 0 && (
                <React.Fragment>
                    <Menu
                        style={{ width: '20%' }}
                        anchorEl={anchorEl}
                        getContentAnchorEl={null}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={menuOpen}
                        onClose={handleCloseMenu}
                    >
                        {notifications.map((notification, index) => (
                            <MenuItem
                                key={index}
                                onClick={(event) => {
                                    handleClickMessage(event, notification);
                                }}
                            >
                                <Typography noWrap>{notification.title}</Typography>
                            </MenuItem>
                        ))}

                        <MenuItem onClick={handleClearAll}>
                            <Typography variant="subtitle2">Clear all</Typography>
                        </MenuItem>
                    </Menu>

                    <Dialog
                        open={dialogOpen}
                        onClose={(event) => {
                            handleCloseDialog(event, selectedNotification);
                        }}
                        aria-labelledby="form-dialog-title"
                        fullWidth={true}
                        maxWidth="lg"
                    >
                        <DialogTitle id="form-dialog-title">
                            {selectedNotification.title}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {selectedNotification.description}
                            </DialogContentText>
                            {selectedNotification.content ? (
                                <Typography style={{ display: 'inline-block' }}>
                                    {selectedNotification.content}
                                </Typography>
                            ) : (
                                ''
                            )}
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={(event) => {
                                    handleCloseDialog(event, selectedNotification);
                                }}
                                color="primary"
                            >
                                Ok
                            </Button>
                        </DialogActions>
                    </Dialog>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};
