import React from 'react';
import { Config, GlobalProvider } from './GlobalContext';
import { AuthProvider } from './AuthContext';
import Layout from './Layout';
import ErrorBoundary from './ErrorBoundary';

interface AppProps {
    config: Config;
}

const App: React.FC<AppProps> = ({ config }) => {
    return (
        <GlobalProvider config={config}>
            <ErrorBoundary>
                <AuthProvider>
                    <Layout />
                </AuthProvider>
            </ErrorBoundary>
        </GlobalProvider>
    );
};

export default App;
