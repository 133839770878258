import { SortDirection, State } from './initialState';
import { Actions } from './actions';

export default function reducer(state: State, action: Actions) {
    const actionType = action.type;

    switch (actionType) {
        case 'setAnchor':
            return { ...state, anchor: action.payload.element, anchorText: action.payload.text };
        case 'setOpenEditDialog':
            return { ...state, openEditDialog: action.payload };
        case 'setPage':
            return { ...state, page: action.payload };
        case 'setUser': {
            let rows = state.rows;

            if (action.payload && action.payload.member_of) {
                let members = action.payload.member_of;
                let stewards = action.payload.steward_on ? action.payload.steward_on : [];
                let r: any[] = [];

                members.forEach((x) =>
                    r.push({
                        name: x.name,
                        active: !!(x.active && x.active === 1),
                        type: x.managed ? 'managed' : 'self',
                        region: x.region,
                        role: x.profile,
                        steward: stewards.find((s) => s.name === x.name) ? 'Yes' : 'No',
                        member: 'Yes',
                    }),
                );
                stewards
                    .filter((x) => !members.find((y) => y.name === x.name))
                    .forEach((x) =>
                        r.push({
                            name: x.name,
                            active: false,
                            type: x.managed ? 'managed' : 'self',
                            region: x.region,
                            role: '',
                            steward: 'Yes',
                            member: 'No',
                        }),
                    );

                rows = r;
            }

            return {
                ...state,
                user: action.payload,
                rows,
                allRows: rows,
            };
        }
        case 'sort': {
            const isAsc = state.orderBy === action.payload && state.order === SortDirection.ASC;
            const order = isAsc ? SortDirection.DESC : SortDirection.ASC;

            return {
                ...state,
                order,
                orderBy: action.payload,
            };
        }
        case 'changeRowsPerPage':
            return {
                ...state,
                rowsPerPage: action.payload,
                page: 0,
            };
        case 'filter': {
            if (action.payload) {
                const filteredRows = state.rows.filter((r) => r.name.includes(action.payload));

                return {
                    ...state,
                    rows: filteredRows,
                };
            } else {
                return {
                    ...state,
                    rows: state.allRows,
                };
            }
        }
        default:
            return state;
    }
}
