import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Criticality, ConfidentialityLevel } from '../../constants';
import { useAuth } from '../../../App/AuthContext';
import { useCommandApi, useQueryApi } from '../../../Apis';
import { useGlobalContext } from '../../../App/GlobalContext';
import { Dataset } from '../../../interfaces/dataset';
import DeleteDialog from '../DeleteDialog';
import reducer from './reducer';
import { initialState } from './initialState';
import { dispatchSetWholeDataset } from './actions';

interface DatasetMetadataProps {
    dataset: Dataset;
    onDeleteDataset: (dataset: Dataset) => void;
}

export default function DatasetMetadata({ dataset, onDeleteDataset }: DatasetMetadataProps) {
    const { authSession } = useAuth();
    const { config, addError, setBackdrop } = useGlobalContext();
    const commandApi = useCommandApi(config, authSession);
    const queryApi = useQueryApi(config, authSession);
    const [state, dispatch] = React.useReducer(reducer, initialState);

    const handleDeleteDataset = () => {
        onDeleteDataset(dataset);
        dispatch({ type: 'setOpenDeleteDialogState', payload: false });
    };

    const handleCancelDeleteDataset = () => {
        dispatch({ type: 'setOpenDeleteDialogState', payload: false });
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        setBackdrop(true);

        try {
            const response = await commandApi.updateDataset({
                name: dataset.name,
                cost_center: state.costCenter,
                topic_arn: state.clientSNSTopicArn,
                domain: state.domain,
                description: state.description,
                company: state.company,
                owner: state.owner,
                criticality: state.criticality,
                confidentiality: state.confidentiality,
                pii: state.pii,
                gxp: state.gxp,
                financial: state.financial,
                env_health_safety: state.ehs,
            });

            if (response?.errorMessage) {
                addError(new Error(response.errorMessage));
            }

            setBackdrop(false);
        } catch (error) {
            console.log(error);
        }
    };

    const validateClientAccount = (event: any) => {
        if (event.target.value.match(/^\d{12}$/)) {
            dispatch({ type: 'setClientAccountHasError', payload: false });
            dispatch({ type: 'setClientAccountErrorText', payload: '' });
            dispatch({ type: 'setHasError', payload: false });
        } else {
            dispatch({ type: 'setClientAccountHasError', payload: true });
            dispatch({ type: 'setClientAccountErrorText', payload: 'Invalid AWS AccountID' });
            dispatch({ type: 'setHasError', payload: true });
        }

        dispatch({ type: 'setClientAccount', payload: event.target.value });
    };

    const validateClientSNSTopicArn = (event: any) => {
        if (event.target.value.match(/^arn:aws:sns:\S+:\d+:\w+/)) {
            dispatch({ type: 'setClientSNSTopicArnHasError', payload: false });
            dispatch({ type: 'setClientSNSTopicArnErrorText', payload: '' });
            dispatch({ type: 'setHasError', payload: false });
        } else {
            dispatch({ type: 'setClientSNSTopicArnHasError', payload: true });
            dispatch({ type: 'setClientSNSTopicArnErrorText', payload: 'Invalid SNS Arn format' });
            dispatch({ type: 'setHasError', payload: true });
        }

        dispatch({ type: 'setClientSNSTopicArn', payload: event.target.value });
    };

    async function validateCostCenter(event: any) {
        dispatch({ type: 'setCostCenter', payload: event.target.value });

        const isValid = await queryApi.validateCostCenter(event.target.value);

        if (isValid) {
            dispatch({ type: 'setCostCenterError', payload: false });
            dispatch({ type: 'setCostCenterErrorText', payload: '' });
            dispatch({ type: 'setHasError', payload: false });
        } else {
            dispatch({ type: 'setCostCenterError', payload: true });
            dispatch({ type: 'setCostCenterErrorText', payload: 'Invalid Cost center' });
            dispatch({ type: 'setHasError', payload: true });
        }
    }

    useEffect(() => {
        if (!dataset) {
            return;
        }

        dispatchSetWholeDataset(dispatch, dataset);
    }, [dataset]);

    return (
        <div>
            <DeleteDialog
                dataset={dataset}
                openState={state.openDeleteDialogState}
                onOk={handleDeleteDataset}
                onCancel={handleCancelDeleteDataset}
            />

            {!(dataset && dataset.managed) ? (
                <Box textAlign="right">
                    <Button
                        disabled={dataset && dataset.managed}
                        onClick={() =>
                            dispatch({ type: 'setOpenDeleteDialogState', payload: true })
                        }
                    >
                        <DeleteIcon color="secondary" />
                    </Button>
                </Box>
            ) : (
                ''
            )}

            <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <Tooltip title="Does this dataset contain 'Financial' data ?">
                    <FormControlLabel
                        control={
                            <Checkbox
                                disabled={false}
                                checked={state.financial}
                                onChange={(event) =>
                                    dispatch({
                                        type: 'setFinancial',
                                        payload: event.target.checked,
                                    })
                                }
                                name="financial"
                                color="primary"
                            />
                        }
                        label="Financial"
                    />
                </Tooltip>

                <Tooltip title="Does this dataset contain 'Environmental, health and safety' (EHS) data">
                    <FormControlLabel
                        control={
                            <Checkbox
                                disabled={false}
                                checked={state.ehs}
                                onChange={(event) =>
                                    dispatch({ type: 'setEhs', payload: event.target.checked })
                                }
                                name="ehs"
                                color="primary"
                            />
                        }
                        label="EHS"
                    />
                </Tooltip>

                <Tooltip title="Does this dataset contain 'GxP' data">
                    <FormControlLabel
                        control={
                            <Checkbox
                                disabled={false}
                                checked={state.gxp}
                                onChange={(event) =>
                                    dispatch({ type: 'setGxp', payload: event.target.checked })
                                }
                                name="GxP"
                                color="primary"
                            />
                        }
                        label="GxP"
                    />
                </Tooltip>

                <Tooltip title="Does this dataset contain 'Personally identifiable information' (PII) data">
                    <FormControlLabel
                        control={
                            <Checkbox
                                disabled={false}
                                checked={state.pii}
                                onChange={(event) =>
                                    dispatch({ type: 'setPii', payload: event.target.checked })
                                }
                                name="PII"
                                color="primary"
                            />
                        }
                        label="PII"
                    />
                </Tooltip>

                <FormControl fullWidth={true} required={true}>
                    <InputLabel id="confidentiality-label-id">Confidentiality level</InputLabel>
                    <Select
                        disabled={false}
                        labelId="confidentiality-label-id"
                        value={state.confidentiality}
                        onChange={(event) =>
                            dispatch({
                                type: 'setConfidentiality',
                                payload: event.target.value as string,
                            })
                        }
                        autoWidth={false}
                        required={true}
                    >
                        {Object.keys(ConfidentialityLevel).map((key) => (
                            <MenuItem key={key} value={key}>
                                {ConfidentialityLevel[key as keyof typeof ConfidentialityLevel]}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl fullWidth={true} required={true} margin="dense">
                    <InputLabel id="criticality-label-id">Criticality</InputLabel>
                    <Select
                        disabled={false}
                        labelId="criticality-label-id"
                        value={state.criticality}
                        onChange={(event) =>
                            dispatch({
                                type: 'setCriticality',
                                payload: event.target.value as string,
                            })
                        }
                        autoWidth={false}
                        required={true}
                    >
                        {Object.keys(Criticality).map((key) => (
                            <MenuItem key={key} value={key}>
                                {Criticality[key as keyof typeof Criticality]}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <TextField
                    disabled={false}
                    margin="dense"
                    id="owner"
                    label="Owner"
                    value={state.owner}
                    fullWidth={true}
                    onChange={(event) =>
                        dispatch({ type: 'setOwner', payload: event.target.value })
                    }
                />
                <TextField
                    disabled={false}
                    margin="dense"
                    id="company"
                    label="Company"
                    value={state.company}
                    fullWidth={true}
                    onChange={(event) =>
                        dispatch({ type: 'setCompany', payload: event.target.value })
                    }
                />
                <TextField
                    disabled={false}
                    margin="dense"
                    id="domain"
                    label="Domain"
                    value={state.domain}
                    fullWidth={true}
                    onChange={(event) =>
                        dispatch({ type: 'setDomain', payload: event.target.value })
                    }
                />
                <TextField
                    disabled={false}
                    margin="dense"
                    id="description"
                    label="Description"
                    value={state.description}
                    fullWidth={true}
                    onChange={(event) =>
                        dispatch({ type: 'setDescription', payload: event.target.value })
                    }
                />
                <TextField
                    disabled={false}
                    margin="dense"
                    id="cost_center"
                    label="Cost Center"
                    error={state.costCenterError}
                    helperText={state.costCenterErrorText}
                    value={state.costCenter}
                    fullWidth={true}
                    onChange={validateCostCenter}
                    required={true}
                />
                <TextField
                    disabled={true}
                    margin="dense"
                    id="clientAccount"
                    label="Client account"
                    error={state.clientAccountHasError}
                    helperText={state.clientAccountErrorText}
                    value={state.clientAccount}
                    fullWidth={true}
                    onChange={validateClientAccount}
                />
                <TextField
                    disabled={false}
                    margin="dense"
                    error={state.clientSNSTopicArnHasError}
                    helperText={state.clientSNSTopicArnErrorText}
                    id="clientSNSTopicArn"
                    label="SNS Topic ARN"
                    value={state.clientSNSTopicArn}
                    fullWidth={true}
                    onChange={validateClientSNSTopicArn}
                />
                <Grid container alignItems="flex-start" justify="flex-end" direction="row">
                    <Button type="submit" color="primary" disabled={state.hasError}>
                        Save
                    </Button>
                </Grid>
            </form>
        </div>
    );
}
