import Box from '@material-ui/core/Box';
import React from 'react';

interface TabPanelProps {
    children: JSX.Element | JSX.Element[];
    index: number;
    value: any;
}

export default function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            style={{ width: '100%' }}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    );
}
