export type State = {
    clientAccount: string;
    clientAccountErrorText: string;
    clientAccountHasError: boolean;
    clientSNSTopicArn: string;
    clientSNSTopicArnErrorText: string;
    clientSNSTopicArnHasError: boolean;
    company: string;
    confidentiality: string;
    costCenter: string;
    costCenterError: boolean;
    costCenterErrorText: string;
    criticality: string;
    description: string;
    domain: string;
    ehs: boolean;
    gxp: boolean;
    hasError: boolean;
    financial: boolean;
    openDeleteDialogState: boolean;
    owner: string;
    pii: boolean;
};

export const initialState: State = {
    clientAccount: '',
    clientAccountErrorText: '',
    clientAccountHasError: false,
    clientSNSTopicArn: '',
    clientSNSTopicArnErrorText: '',
    clientSNSTopicArnHasError: false,
    company: '',
    confidentiality: '',
    costCenter: '',
    costCenterError: false,
    costCenterErrorText: '',
    criticality: '',
    description: '',
    domain: '',
    ehs: false,
    gxp: false,
    hasError: false,
    financial: false,
    openDeleteDialogState: false,
    owner: '',
    pii: false,
};
