import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import LayersIcon from '@mui/icons-material/Layers';
import GroupWorkOutlinedIcon from '@mui/icons-material/GroupWorkOutlined';
import HistoryIcon from '@mui/icons-material/History';
import StorageIcon from '@mui/icons-material/Storage';
import PersonOutline from '@mui/icons-material/PersonOutline';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import CheckIcon from '@mui/icons-material/Check';
import BugReportIcon from '@mui/icons-material/BugReport';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useCommandApi, useQueryApi } from '../../Apis';
import { useAuth } from '../../App/AuthContext';
import { useGlobalContext } from '../../App/GlobalContext';
import CircularProgressWrapper from '../../Common/CircularProgressWrapper';
import Title from '../../Common/Title';
import { Dataset as DatasetObj } from '../../interfaces/dataset';
import DatasetMembers from './Members';
import DatasetMetadata from './Metadata';
import DatasetStewards from './Stewards';
import DatasetGroups from './Groups';
import DatasetData from './Data';
import DatasetLifecycle from './Lifecycle';
import DatasetCrawlers from './Crawlers';
import TabPanel from './TabPanel';
import ConfirmDialog from '../../Common/ConfirmDialog';
import { Profile } from '../../interfaces/profile';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
    },
}));

function a11yProps(name: string) {
    const a = {
        Dataset: 0,
        Members: 1,
        Groups: 2,
        Stewards: 3,
        Data: 4,
        Lifecycle: 5,
        Crawlers: 6,
    };
    const index = a[name as keyof typeof a];

    return {
        index: index,
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

type Group = { name: string };

export default function Dataset() {
    const classes = useStyles();
    const navigate = useNavigate();
    const { identity, authSession } = useAuth();
    const { config, isFeatureEnabled } = useGlobalContext();
    const queryApi = useQueryApi(config, authSession);
    const commandApi = useCommandApi(config, authSession);
    const { name } = useParams();
    const datasetName = name!;
    const [activeTab, setActiveTab] = React.useState(0);
    const [dataset, setDataset] = React.useState(null as DatasetObj | null);
    const [users, setUsers] = React.useState([]);
    const [usersAreLoading, setUsersAreLoading] = React.useState(false);
    const [isMember, setIsMember] = React.useState(false);
    const [isSteward, setIsSteward] = React.useState(false);
    const [profiles, setProfiles] = React.useState([]);
    const [groups, setGroups] = React.useState([]);
    const [groupMembers, setGroupMembers] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [showStewardRemovalErrorDialog, setShowStewardRemovalErrorDialog] = React.useState(false);

    async function initializeData() {
        try {
            const [dataset, g, gm] = await Promise.all([
                queryApi.getDataset(datasetName),
                queryApi.getAllGroups(),
                queryApi.getGroups(datasetName),
            ]);
            let gMembers = [];

            setUsersAreLoading(true);
            setDataset(dataset);

            if (gm) {
                gMembers = gm.map((x: any) => {
                    let p = x.profile;
                    let index = x.profile.lastIndexOf('#');
                    if (index !== -1) {
                        p = x.profile.substring(index + 1);
                    }
                    return {
                        name: x.name,
                        profile: p,
                    };
                });
            }

            const filteredGroups = g
                .map((x: any) => {
                    return { name: x };
                })
                .filter((k: any) => {
                    if (!gm) {
                        return true;
                    }
                    return !gm.find((x: any) => k.name === x.name);
                });

            setGroups(filteredGroups);
            setGroupMembers(gMembers);

            // this request takes 5 seconds, it's good to run it last, in order to be able to show the ui sooner
            const users = await queryApi.getAllUsers();

            setUsers(users);
            setUsersAreLoading(false);
        } catch (e) {
            console.error(e);
        }
    }

    async function initializeProfiles() {
        try {
            const pr = await queryApi.getProfiles();

            setProfiles(pr);
        } catch (e) {
            console.error(e);
        }
    }

    useEffect(() => {
        initializeData();
    }, [datasetName, queryApi]);

    useEffect(() => {
        if (!dataset) {
            return;
        }

        const isMember = dataset
            ? dataset.members.filter((member) => member.username === identity!.username).length > 0
            : false;
        const isSteward = dataset
            ? dataset.stewards.filter((steward) => steward === identity!.username).length > 0
            : false;

        setIsLoading(false);
        setIsMember(isMember);
        setIsSteward(isSteward);

        if (!isSteward) {
            setActiveTab(4);
        }
    }, [dataset]);

    useEffect(() => {
        initializeProfiles();
    }, []);

    const handleChange = async (event: any, newValue: any) => {
        setActiveTab(newValue);
    };

    async function handleDeleteDataset(dataset: { name: string }) {
        try {
            await commandApi.deleteDataset(dataset.name);
            navigate('/');
        } catch (err) {
            console.error(err);
        }
    }

    async function handleAddMember(user: { username: string }, profile: any) {
        try {
            await commandApi.addMember(datasetName, user.username, profile);
            await initializeData();
        } catch (e) {
            console.error(e);
        }
    }
    async function handleRemoveMember(member: { username: string }) {
        try {
            await commandApi.removeMember(datasetName, member.username);
            await initializeData();
        } catch (e) {
            console.error(e);
        }
    }

    async function handleAddSteward(user: { username: string }) {
        try {
            const resp = await commandApi.addSteward(datasetName, user.username);
            await initializeData();
        } catch (e) {
            console.error(e);
        }
    }

    async function handleRemoveSteward(member: { username: string }) {
        try {
            if (dataset?.stewards.length === 1) {
                setShowStewardRemovalErrorDialog(true);

                return;
            }

            await commandApi.removeSteward(datasetName, member['username']);
            await initializeData();
        } catch (e) {
            console.error(e);
        }
    }

    async function handleChangeProfile(user: Profile) {
        try {
            console.log('change profile for user:', user);
            await commandApi.addMember(datasetName, user.username, user.profile);
            await initializeData();
        } catch (e) {
            console.error(e);
        }
    }

    async function handleChangeGroupProfile(group: Profile) {
        try {
            console.log('change profile for group:', group);
            await commandApi.updateGroupProfile(datasetName, group.name!, group.profile);
            await initializeData();
        } catch (e) {
            console.error(e);
        }
    }

    async function handleAddGroup(group: Group, profile: any) {
        try {
            await commandApi.addGroup(datasetName, group.name, profile);
            await initializeData();
        } catch (e) {
            console.error(e);
        }
    }

    async function handleRemoveGroup(group: Group) {
        try {
            await commandApi.removeGroup(datasetName, group.name);
            await initializeData();
        } catch (e) {
            console.error(e);
        }
    }

    const renderCrawler = () => {
        if (isFeatureEnabled('crawler')) {
            return (
                <Tab
                    disabled={!isSteward}
                    label={'Crawlers'}
                    icon={<BugReportIcon />}
                    {...a11yProps('Crawlers')}
                />
            );
        }
    };

    return (
        <React.Fragment>
            <Title>
                <div>
                    {dataset ? dataset.name?.toUpperCase() : ''}
                    {dataset && dataset.managed ? <CheckIcon /> : ''}
                </div>
                <div style={{ fontSize: 'small', fontWeight: 300 }}>
                    {dataset ? dataset.region : ''}
                </div>
            </Title>
            <ConfirmDialog
                id="remove-steward-dialog-error"
                isBinary={false}
                title="Steward removal error"
                message="You can't remove a steward from a dataset if there's only a single steward assigned."
                isOpen={showStewardRemovalErrorDialog}
                handleClosed={() => setShowStewardRemovalErrorDialog(false)}
                handleCancel={() => setShowStewardRemovalErrorDialog(false)}
            />

            <div className={classes.root}>
                {isLoading && <CircularProgressWrapper />}
                {!isLoading && (
                    <>
                        <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            value={activeTab}
                            onChange={handleChange}
                            aria-label="Vertical tabs example"
                            className={classes.tabs}
                        >
                            <Tab
                                disabled={!isMember || !isSteward}
                                label={'Dataset'}
                                icon={<LayersIcon />}
                                {...a11yProps('Dataset')}
                            />
                            <Tab
                                disabled={(dataset && dataset.managed) || !isSteward}
                                label={'Members'}
                                icon={<PersonOutline />}
                                {...a11yProps('Members')}
                            />
                            <Tab
                                disabled={(dataset && dataset.managed) || !isSteward}
                                label={'Groups'}
                                icon={<PeopleOutlineIcon />}
                                {...a11yProps('Groups')}
                            />
                            <Tab
                                disabled={!isSteward}
                                label={'Stewards'}
                                icon={<GroupWorkOutlinedIcon />}
                                {...a11yProps('Stewards')}
                            />
                            <Tab
                                disabled={!isMember}
                                label={'Data'}
                                icon={<StorageIcon />}
                                {...a11yProps('Data')}
                            />
                            <Tab
                                disabled={!isSteward}
                                label={'Lifecycle'}
                                icon={<HistoryIcon />}
                                {...a11yProps('Lifecycle')}
                            ></Tab>
                            {renderCrawler()}
                        </Tabs>

                        <TabPanel value={activeTab} index={a11yProps('Dataset').index}>
                            <DatasetMetadata
                                dataset={dataset as DatasetObj}
                                onDeleteDataset={handleDeleteDataset}
                            />
                        </TabPanel>

                        <TabPanel value={activeTab} index={a11yProps('Members').index}>
                            {usersAreLoading ? (
                                <CircularProgressWrapper />
                            ) : (
                                <DatasetMembers
                                    members={dataset ? dataset.members : []}
                                    users={users ? users : []}
                                    onAddMember={handleAddMember}
                                    onRemoveMember={handleRemoveMember}
                                    onChangeProfile={handleChangeProfile}
                                    profiles={profiles ? profiles : []}
                                />
                            )}
                        </TabPanel>

                        <TabPanel value={activeTab} index={a11yProps('Groups').index}>
                            <DatasetGroups
                                groups={groups ? groups : []}
                                members={groupMembers ? groupMembers : []}
                                onAddGroup={handleAddGroup}
                                onRemoveGroup={handleRemoveGroup}
                                onChangeProfile={handleChangeGroupProfile}
                                profiles={profiles ? profiles : []}
                            />
                        </TabPanel>

                        <TabPanel value={activeTab} index={a11yProps('Stewards').index}>
                            {usersAreLoading ? (
                                <CircularProgressWrapper />
                            ) : (
                                <DatasetStewards
                                    stewards={dataset ? dataset.stewards : []}
                                    users={users ? users : []}
                                    onAddSteward={handleAddSteward}
                                    onRemoveSteward={handleRemoveSteward}
                                />
                            )}
                        </TabPanel>

                        <TabPanel value={activeTab} index={a11yProps('Data').index}>
                            <DatasetData dataset={dataset as DatasetObj} />
                        </TabPanel>

                        <TabPanel value={activeTab} index={a11yProps('Lifecycle').index}>
                            <DatasetLifecycle dataset={dataset as DatasetObj} />
                        </TabPanel>

                        <TabPanel value={activeTab} index={a11yProps('Crawlers').index}>
                            <DatasetCrawlers dataset={dataset as DatasetObj} />
                        </TabPanel>
                    </>
                )}
            </div>
        </React.Fragment>
    );
}
