import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';

interface CreateFolderDialogProps {
    openState: boolean;
    onCancel: (value: string) => void;
    onSave: (folderName: string) => void;
    prefix: string;
}

function CreateFolderDialog({ openState, onCancel, onSave, prefix }: CreateFolderDialogProps) {
    const [folderName, setFolderName] = React.useState('');
    const [folderNameError, setFolderNameError] = React.useState(false);
    const [folderNameErrorText, setFolderNameErrorText] = React.useState('');

    const handleCancel = (event: any) => {
        event.preventDefault();
        onCancel(event.target.value);
    };

    const handleEnter = () => {
        setFolderName('');
        setFolderNameError(true);
        setFolderNameErrorText('Folder name cannot be empty');
    };

    const validateFolderName = (event: any) => {
        if (event.target.value) {
            const match = event.target.value.match(
                /(?=^.{3,63}$)(?!^(\d+\.)+\d+$)(^(([a-z0-9]|[a-z0-9][a-z0-9\-]*[a-z0-9])\.)*([a-z0-9]|[a-z0-9][a-z0-9\-]*[a-z0-9])$)/,
            );
            if (!match) {
                setFolderNameError(true);
                setFolderNameErrorText(
                    'Invalid folder name. Must be all lowercase and between 3 to 63 characters. Must not contain underscores',
                );
            } else {
                setFolderNameError(false);
                setFolderNameErrorText('');
            }
        } else {
            setFolderNameError(true);
            setFolderNameErrorText(
                'Invalid folder name. Must be all lowercase and between 3 to 63 characters. Must not contain underscores',
            );
        }
        setFolderName(event.target.value);
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        onSave(folderName);
    };

    return (
        <div>
            <Dialog
                open={openState}
                onClose={handleCancel}
                onEnter={handleEnter}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth="lg"
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
            >
                <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                    <DialogTitle id="create-folder-dialog-title">
                        {'Create folder in ' + prefix}
                    </DialogTitle>

                    <DialogContent>
                        <TextField
                            autoFocus={true}
                            margin="dense"
                            id="folder-name-textfield"
                            label="Folder name"
                            fullWidth={true}
                            value={folderName}
                            error={folderNameError}
                            helperText={folderNameErrorText}
                            onChange={validateFolderName}
                            required={true}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button type="submit" color="primary" disabled={folderNameError}>
                            Save
                        </Button>
                        <Button onClick={handleCancel} color="primary">
                            Cancel
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
}

export default CreateFolderDialog;
