import { CognitoIdentity, STS } from 'aws-sdk';
import { GlobalRegion, Regions } from '../constants';
import { Identity } from '../App/AuthContext';

const cognitoIdentity = new CognitoIdentity({
    region: 'eu-central-1',
});

async function getCognitoId(
    accountId: any,
    cognitoIdP: string | number,
    id_token: any,
    identityPoolId: any,
) {
    const logins: { [key: string]: string } = {};
    logins[cognitoIdP] = id_token;
    const cognitoParams = {
        IdentityPoolId: identityPoolId,
        AccountId: accountId,
        Logins: logins,
    };
    return await cognitoIdentity.getId(cognitoParams).promise();
}

async function getOpenId(
    cognitoIdP: string | number,
    identityId: CognitoIdentity.IdentityId | undefined,
    id_token: any,
) {
    const logins: { [key: string]: string } = {};
    logins[cognitoIdP] = id_token;
    const oid_params = {
        IdentityId: identityId!,
        Logins: logins,
    };
    return await cognitoIdentity.getOpenIdToken(oid_params).promise();
}

async function assumeDatalakeRole(
    sts: STS,
    user: string,
    accountid: any,
    rolebasename: string,
    webIdToken: CognitoIdentity.OIDCToken | undefined,
) {
    const role = 'arn:aws:iam::' + accountid + ':role/' + rolebasename + '-' + user;
    const assume_role_params = {
        DurationSeconds: 43200,
        RoleArn: role,
        RoleSessionName: 'nnedl',
        WebIdentityToken: webIdToken!,
    };
    return sts.assumeRoleWithWebIdentity(assume_role_params).promise();
}

export async function getCredentials(identity: any, config: any) {
    const cognitoIdP = `cognito-idp.${GlobalRegion}.amazonaws.com/${config.userPoolId}`;
    const identityPoolId = config['identityPoolId'];
    const roleBaseName = 'DatalakeRole';
    const accountId = config.accountId;
    const id = await getCognitoId(accountId, cognitoIdP, identity.idToken, identityPoolId);
    const openId = await getOpenId(cognitoIdP, id.IdentityId, identity.idToken);
    const sts = new STS({
        region: GlobalRegion,
    });
    const roleCreds = await assumeDatalakeRole(
        sts,
        identity.username,
        accountId,
        roleBaseName,
        openId.Token,
    );
    const access_key = roleCreds.Credentials!.AccessKeyId;
    const secrets_key = roleCreds.Credentials!.SecretAccessKey;
    const session_token = roleCreds.Credentials!.SessionToken;

    return {
        accessKeyId: access_key,
        secretAccessKey: secrets_key,
        sessionToken: session_token,
    };
}

type Dataset = {
    active: number;
    region: string;
    profile: string;
    name: string;
};

type RsData = {
    redshiftClusterNames: { [key: string]: string };
    redshiftDbNames: { [key: string]: string };
};

type Creds = {
    accessKeyId: string;
    secretAccessKey: string;
    sessionToken: string;
};

type Profile = { permissions: string; name: string };

export function getRedshiftStrings(
    datasets: Dataset[],
    identity: Identity,
    rsData: RsData,
    creds: Creds,
    profiles: Profile[],
) {
    const rsstrings: { [key: string]: { jdbcurl: string; rjdbcurl: string } } = {};

    if (datasets?.length > 0) {
        const regional_datasets: { [key: string]: { datasets: any[]; dbgroups: string[] } } = {};

        Regions.forEach((region) => {
            regional_datasets[region] = {
                datasets: [],
                dbgroups: [],
            };
            datasets
                .filter((ds) => ds.active)
                .forEach((ds) => {
                    if (ds.region === region) {
                        profiles.forEach((profile) => {
                            if (profile.name === ds.profile) {
                                const permission = profile.permissions.slice(-1);
                                regional_datasets[region].datasets.push(ds);
                                regional_datasets[region].dbgroups.push(ds.name + '_ext');
                                if (permission === 'r') {
                                    regional_datasets[region].dbgroups.push(ds.name + '_read');
                                } else if (permission === 'w') {
                                    regional_datasets[region].dbgroups.push(ds.name + '_write');
                                }
                            }
                        });
                    }
                });
        });

        Object.keys(regional_datasets).forEach((region) => {
            const dataset = regional_datasets[region]['datasets'][0];

            if (dataset != null) {
                const clusterName = rsData.redshiftClusterNames[region];
                const dbName = rsData.redshiftDbNames[region];
                const dbGroups = regional_datasets[region]['dbgroups'];

                const jdbcUrl = new URL(`jdbc:redshift:iam://${clusterName}:${region}/${dbName}`);
                jdbcUrl.searchParams.append('Profile', 'dl_user');
                jdbcUrl.searchParams.append('DbUser', identity.username);
                // @ts-ignore
                jdbcUrl.searchParams.append('AutoCreate', true);
                jdbcUrl.searchParams.append('DbGroups', dbGroups.join().toLowerCase());
                const jdbcString = decodeURIComponent(jdbcUrl.href);

                jdbcUrl.searchParams.append('AccessKeyID', creds.accessKeyId);
                jdbcUrl.searchParams.append('SecretAccessKey', creds.secretAccessKey);
                jdbcUrl.searchParams.append('SessionToken', creds.sessionToken);
                const rJdbcString = decodeURIComponent(jdbcUrl.href);

                rsstrings[region] = {
                    jdbcurl: jdbcString.toLowerCase(),
                    rjdbcurl: rJdbcString.toLowerCase(),
                };
            }
        });
    }

    return rsstrings;
}
