import { Credentials, S3 } from 'aws-sdk';
import { CredentialsOptions } from 'aws-sdk/lib/credentials';

export async function downloadFile(
    bucket: string,
    key: string,
    bucketRegion: string,
    creds: Credentials | CredentialsOptions,
    expireDuration: number,
) {
    const params = {
        Bucket: bucket,
        Key: key,
        Expires: expireDuration,
        ResponseContentDisposition: 'attachment',
    };
    const s3 = new S3({ region: bucketRegion, credentials: creds });
    const url = await s3.getSignedUrlPromise('getObject', params);

    return url;
}
