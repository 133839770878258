import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import MaterialTable from 'material-table';
import CheckIcon from '@mui/icons-material/Check';
import RemoveIcon from '@mui/icons-material/Remove';
import { useAuth } from '../App/AuthContext';
import NewDatasetDialog from './NewDatasetDialog';
import { useCommandApi, useQueryApi } from '../Apis';
import { useGlobalContext } from '../App/GlobalContext';
import { Dataset } from '../interfaces/dataset';

function Datasets() {
    const { authSession, logout } = useAuth();
    const { config, setBackdrop } = useGlobalContext();
    const navigate = useNavigate();
    const queryApi = useQueryApi(config, authSession);
    const commandApi = useCommandApi(config, authSession);

    const [selectedDataset, setSelectedDataset] = React.useState(null as Dataset | null);
    const [openEditDialogState, setOpenEditDialog] = React.useState(false);
    const [user, setUser] = React.useState(false);

    const { identity } = useAuth();

    const [state, setState] = React.useState({
        columns: [
            { title: 'Dataset', field: 'name' },
            { title: 'Region', field: 'region' },
            { title: 'Domain', field: 'domain', hidden: true },
            { title: 'Owner', field: 'owner', hidden: true },
            { title: 'Cost Center', field: 'cost_center' },
            { title: 'Company', field: 'company', hidden: true },
            {
                title: 'Managed',
                field: 'managed',
                render: (rowData: Dataset) => {
                    if (rowData.managed === true) {
                        return <CheckIcon />;
                    } else {
                        return <RemoveIcon />;
                    }
                },
            },
        ],
        data: [],
    });

    async function initializaData() {
        try {
            const user = await queryApi.getUser(identity!.username);
            setUser(user);
        } catch (err) {
            logout();
        }
    }

    useEffect(() => {
        initializaData();
    }, [identity, authSession, queryApi, logout]);

    useEffect(() => {
        setState((state) => ({
            columns: state.columns,
            // @ts-ignore
            data: user['steward_on'] ? user['steward_on'] : [],
        }));
    }, [user]);

    const handleOpenNewDialog = () => {
        setOpenEditDialog(true);
    };

    const handleCancelNewDatasetDialog = () => {
        setOpenEditDialog(false);
    };

    async function handleSubmitNewDatasetDialog(dataset: Dataset) {
        try {
            await commandApi.createDataset(dataset);
            setOpenEditDialog(false);
            initializaData();
            setBackdrop(false);
        } catch (err) {
            logout();
        }
    }

    const handleGotoEditDataset = (dataset: Dataset) => {
        navigate('/datasets/' + dataset.name);
    };

    return (
        <React.Fragment>
            <NewDatasetDialog
                openState={openEditDialogState}
                onSubmitForm={handleSubmitNewDatasetDialog}
                onCancel={handleCancelNewDatasetDialog}
            />
            <MaterialTable
                title="My Datasets"
                columns={state.columns}
                data={state.data}
                options={{
                    actionsColumnIndex: -1,
                    grouping: false,
                }}
                actions={[
                    {
                        icon: 'edit',
                        tooltip: 'Edit dataset',
                        onClick: (event, dataset) => {
                            setSelectedDataset(dataset as Dataset);
                            handleGotoEditDataset(dataset as Dataset);
                        },
                    },
                    {
                        icon: 'add',
                        isFreeAction: true,
                        disabled: identity!.steward === 'NA' || identity!.steward === undefined,
                        tooltip: 'New dataset',
                        onClick: (event) => {
                            handleOpenNewDialog();
                        },
                    },
                ]}
            />
        </React.Fragment>
    );
}

export default Datasets;
