import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { useAuth } from '../../App/AuthContext';
import { useCommandApi, useQueryApi } from '../../Apis';
import { useGlobalContext } from '../../App/GlobalContext';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Tooltip from '@material-ui/core/Tooltip';
import EditCrawlerDialog from '../EditCrawlerDialog';
import CreateIcon from '@mui/icons-material/Create';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import StopIcon from '@mui/icons-material/Stop';
import { green, blue, red } from '@material-ui/core/colors';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Fab from '@material-ui/core/Fab';
import ConfirmDialog from '../../Common/ConfirmDialog';
import NewCrawlerDialog from '../NewCrawlerDialog';
import RefreshIcon from '@mui/icons-material/Refresh';
import Box from '@material-ui/core/Box';
import { Dataset } from '../../interfaces/dataset';
import { Crawler } from '../../interfaces/crawler';
import { Column } from '../../interfaces/column';
import { CircularProgress } from '@material-ui/core';

const columns: Column[] = [
    { id: 'name', label: 'Name', minWidth: 170, actions: true, align: 'inherit' },
    { id: 'schedule', label: 'Schedule', minWidth: 50, align: 'inherit' },
    { id: 'state', label: 'state', minWidth: 50, align: 'inherit' },
    { id: 'path', label: 'Path', minWidth: 100, align: 'inherit' },
];
const removeCrawlerMessage = 'Are you sure you want to delete the crawler?';
const removeCrawlerTitle = '';
const stopCrawlerMessage = 'Are you sure you want to stop the crawler?';
const startCrawlerMessage = 'Are you sure you want to start the crawler?';
const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 440,
    },
    editCrawler: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
    startCrawler: {
        color: green[500],
        '&:hover': {
            cursor: 'pointer',
        },
    },
    stopCrawler: {
        color: blue[500],
        '&:hover': {
            cursor: 'pointer',
        },
    },
    deleteCrawler: {
        color: red[500],
        '&:hover': {
            cursor: 'pointer',
        },
    },
    refreshButton: {
        marginLeft: 10,
    },
    spinner: {
        marginLeft: '50%',
    },
});

function DatasetCrawlers({ dataset }: { dataset: Dataset }) {
    const { authSession, logout } = useAuth();
    const { config, setBackdrop, addError } = useGlobalContext();
    const commandApi = useCommandApi(config, authSession);
    const queryApi = useQueryApi(config, authSession);
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [rows, setRows] = React.useState([] as Crawler[]);
    const [openEditDialogState, setOpenEditDialog] = React.useState(false);
    const [openNewCrawlerDialogState, setOpenNewCrawlerDialog] = React.useState(false);
    const [selectedCrawler, setSelectedCrawler] = React.useState(null as Crawler | null);
    const [deleteCrawler, setDeleteCrawler] = React.useState(null as Crawler | null);
    const [startCrawler, setStartCrawler] = React.useState(null as Crawler | null);
    const [stopCrawler, setStopCrawler] = React.useState(null as Crawler | null);
    const [showRemoveCrawlerDialog, setShowRemoveCrawlerDialog] = React.useState(false);
    const [showStartCrawlerDialog, setShowStartCrawlerDialog] = React.useState(false);
    const [showStopCrawlerDialog, setShowStopCrawlerDialog] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const handleChangePage = (event: any, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleCancelEditCrawlerDialog = () => {
        setSelectedCrawler(null);
        setOpenEditDialog(false);
    };

    const handleCancelNewCrawlerDialog = () => {
        setOpenNewCrawlerDialog(false);
    };

    const closeRemoveCrawlerDialog = async () => {
        setShowRemoveCrawlerDialog(false);
        try {
            const response = await commandApi.deleteCrawler(
                deleteCrawler!.name,
                dataset.region!,
                dataset.name,
            );
            console.log(response);
            if (response && response.errorMessage) {
                addError(new Error(response.errorMessage));
            }
            initializaData();
        } catch (error) {
            logout();
        }
    };

    const cancelRemoveCrawlerDialog = () => {
        setShowRemoveCrawlerDialog(false);
    };

    const closeStartCrawlerDialog = async () => {
        setShowStartCrawlerDialog(false);
        try {
            const response = await commandApi.startCrawler(
                startCrawler!.name,
                dataset.region!,
                dataset.name,
            );
            console.log(response);
            if (response && response.errorMessage) {
                addError(new Error(response.errorMessage));
            }
        } catch (error) {
            logout();
        }
    };

    const cancelStartCrawlerDialog = () => {
        setShowStartCrawlerDialog(false);
    };

    const closeStopCrawlerDialog = async () => {
        setShowStopCrawlerDialog(false);
        try {
            const response = await commandApi.stopCrawler(
                stopCrawler!.name,
                dataset.region!,
                dataset.name,
            );
            console.log(response);
            if (response && response.errorMessage) {
                addError(new Error(response.errorMessage));
            }
        } catch (error) {
            logout();
        }
    };

    const cancelStopCrawlerDialog = () => {
        setShowStopCrawlerDialog(false);
    };

    const isAuditCrawler = (crawler: Crawler) => {
        if (crawler.path) {
            return crawler.path.indexOf('auditlog/logs/') > -1;
        }
        return false;
    };

    async function handleSubmitEditCrawlerDialog(crawler: Crawler) {
        try {
            console.log('Update crawler schedule: ', crawler);
            const response = await commandApi.updateCrawlerSchedule(
                crawler.name,
                crawler.schedule,
                dataset.region!,
                dataset.name,
            );
            if (response && response.errorMessage) {
                addError(new Error(response.errorMessage));
            }
            setSelectedCrawler(null);
            setOpenEditDialog(false);
            initializaData();
            setBackdrop(false);
        } catch (err) {
            console.log(err);
        }
    }

    async function handleSubmitNewCrawlerDialog(crawler: any) {
        try {
            const response = await commandApi.createCrawler(crawler);
            setOpenNewCrawlerDialog(false);
            initializaData();
            setBackdrop(false);
        } catch (err) {
            console.log(err);
        }
    }

    async function initializaData() {
        setLoading(true);
        try {
            const crawlers = await queryApi.getCrawlers(dataset.name, dataset.region!);
            let r: Crawler[] = [];

            console.log('crawlers: ', crawlers);

            if (crawlers) {
                const crawlerData = crawlers.map((x) => {
                    let scheduleExpr = '';
                    let path = '';

                    if (x.Targets.S3Targets && x.Targets.S3Targets.length > 0) {
                        path = x.Targets.S3Targets[0].Path;
                    }
                    if (x.Schedule && x.Schedule.ScheduleExpression) {
                        scheduleExpr = x.Schedule.ScheduleExpression;
                        if (
                            scheduleExpr &&
                            scheduleExpr.startsWith('cron(') &&
                            scheduleExpr.endsWith(')')
                        ) {
                            const startIndex = scheduleExpr.indexOf('cron(');
                            const endIndex = scheduleExpr.indexOf(')');
                            if (startIndex > -1 && endIndex > -1) {
                                scheduleExpr = scheduleExpr.substring(
                                    'cron('.length,
                                    scheduleExpr.length - 1,
                                );
                            }
                        }
                    }
                    return {
                        name: x.Name,
                        schedule: scheduleExpr,
                        path: path,
                        state: x.State,
                    } as Crawler;
                });

                r = crawlerData.filter((x) => !isAuditCrawler(x));
            }
            setRows(r);
        } catch (err) {
            console.log(err);
            // @ts-ignore
            addError(new Error(err.toString()));
        } finally {
            setLoading(false);
        }
    }

    const refresh = () => {
        try {
            initializaData();
        } catch (error) {
            // @ts-ignore
            logout(error);
        }
    };

    const handleEditCrawler = (crawler: Crawler) => {
        setSelectedCrawler(crawler);
        setOpenEditDialog(true);
    };

    const handleDeleteCrawler = (crawler: Crawler) => {
        setDeleteCrawler(crawler);
        console.log('delete crawler: ', crawler);
        setShowRemoveCrawlerDialog(true);
    };

    const handleStartCrawler = (crawler: Crawler) => {
        setStartCrawler(crawler);
        console.log('start crawler: ', crawler);
        setShowStartCrawlerDialog(true);
    };

    const handleStopCrawler = (crawler: Crawler) => {
        setStopCrawler(crawler);
        console.log('stop crawler: ', crawler);
        setShowStopCrawlerDialog(true);
    };

    const handleNewCrawlerDialog = () => {
        setOpenNewCrawlerDialog(true);
    };

    useEffect(() => {
        initializaData();
    }, [authSession, queryApi, logout]);

    return (
        <Card>
            <ConfirmDialog
                id="remove-crawler-dialog"
                title={deleteCrawler ? deleteCrawler.name : removeCrawlerTitle}
                message={removeCrawlerMessage}
                isOpen={showRemoveCrawlerDialog}
                handleClosed={closeRemoveCrawlerDialog}
                handleCancel={cancelRemoveCrawlerDialog}
            />
            <ConfirmDialog
                id="start-crawler-dialog"
                title={startCrawler ? startCrawler.name : ''}
                message={startCrawlerMessage}
                isOpen={showStartCrawlerDialog}
                handleClosed={closeStartCrawlerDialog}
                handleCancel={cancelStartCrawlerDialog}
            />
            <ConfirmDialog
                id="stop-crawler-dialog"
                title={stopCrawler ? stopCrawler.name : ''}
                message={stopCrawlerMessage}
                isOpen={showStopCrawlerDialog}
                handleClosed={closeStopCrawlerDialog}
                handleCancel={cancelStopCrawlerDialog}
            />
            <EditCrawlerDialog
                openState={openEditDialogState}
                onSubmitForm={handleSubmitEditCrawlerDialog}
                onCancel={handleCancelEditCrawlerDialog}
                crawler={selectedCrawler}
            />
            <NewCrawlerDialog
                openState={openNewCrawlerDialogState}
                onSubmitForm={handleSubmitNewCrawlerDialog}
                onCancel={handleCancelNewCrawlerDialog}
                dataset={dataset}
            />
            <CardHeader
                title="Crawlers"
                action={
                    <Box>
                        <Tooltip title="Create new crawler" aria-label="add">
                            <Fab
                                id="create-crawler"
                                color="primary"
                                aria-label="add"
                                onClick={handleNewCrawlerDialog}
                            >
                                <AddIcon />
                            </Fab>
                        </Tooltip>
                        <Tooltip title="Refresh" aria-label="refresh">
                            <Fab
                                className={classes.refreshButton}
                                id="refresh-crawlers"
                                color="primary"
                                aria-label="refresh"
                                onClick={refresh}
                            >
                                <RefreshIcon />
                            </Fab>
                        </Tooltip>
                    </Box>
                }
            ></CardHeader>
            <CardContent>
                {loading ? (
                    <CircularProgress className={classes.spinner} />
                ) : (
                    <Paper>
                        <TableContainer className={classes.container}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((crawler) => {
                                            return (
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    tabIndex={-1}
                                                    key={crawler.name}
                                                >
                                                    {columns.map((column) => {
                                                        const value =
                                                            crawler[
                                                                column.id as keyof typeof crawler
                                                            ];

                                                        return (
                                                            <TableCell
                                                                key={column.id}
                                                                align={column.align}
                                                            >
                                                                {column.actions ? (
                                                                    <span>
                                                                        <Tooltip title="Edit crawler">
                                                                            <CreateIcon
                                                                                className={
                                                                                    classes.editCrawler
                                                                                }
                                                                                onClick={() =>
                                                                                    handleEditCrawler(
                                                                                        crawler,
                                                                                    )
                                                                                }
                                                                            />
                                                                        </Tooltip>
                                                                        <Tooltip title="Start crawler">
                                                                            <PlayCircleFilledWhiteIcon
                                                                                className={
                                                                                    classes.startCrawler
                                                                                }
                                                                                onClick={() =>
                                                                                    handleStartCrawler(
                                                                                        crawler,
                                                                                    )
                                                                                }
                                                                            ></PlayCircleFilledWhiteIcon>
                                                                        </Tooltip>
                                                                        <Tooltip title="Stop crawler">
                                                                            <StopIcon
                                                                                className={
                                                                                    classes.stopCrawler
                                                                                }
                                                                                onClick={() =>
                                                                                    handleStopCrawler(
                                                                                        crawler,
                                                                                    )
                                                                                }
                                                                            ></StopIcon>
                                                                        </Tooltip>
                                                                        <Tooltip title="Delete crawler">
                                                                            <DeleteIcon
                                                                                className={
                                                                                    classes.deleteCrawler
                                                                                }
                                                                                onClick={() =>
                                                                                    handleDeleteCrawler(
                                                                                        crawler,
                                                                                    )
                                                                                }
                                                                            ></DeleteIcon>
                                                                        </Tooltip>
                                                                        {value}
                                                                    </span>
                                                                ) : (
                                                                    value
                                                                )}
                                                            </TableCell>
                                                        );
                                                    })}
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                )}
            </CardContent>
        </Card>
    );
}

export default DatasetCrawlers;
