import { State } from './initialState';
import { Actions } from './actions';

export default function reducer(state: State, action: Actions) {
    const actionType = action.type;

    switch (actionType) {
        case 'setClientAccount':
            return { ...state, clientAccount: action.payload };
        case 'setClientAccountErrorText':
            return { ...state, clientAccountErrorText: action.payload };
        case 'setClientAccountHasError':
            return { ...state, clientAccountHasError: action.payload };
        case 'setClientSNSTopicArn':
            return { ...state, clientSNSTopicArn: action.payload };
        case 'setClientSNSTopicArnErrorText':
            return { ...state, clientSNSTopicArnErrorText: action.payload };
        case 'setClientSNSTopicArnHasError':
            return { ...state, clientSNSTopicArnHasError: action.payload };
        case 'setCompany':
            return { ...state, company: action.payload };
        case 'setConfidentiality':
            return { ...state, confidentiality: action.payload };
        case 'setCostCenter':
            return { ...state, costCenter: action.payload };
        case 'setCostCenterError':
            return { ...state, costCenterError: action.payload };
        case 'setCostCenterErrorText':
            return { ...state, costCenterErrorText: action.payload };
        case 'setCriticality':
            return { ...state, criticality: action.payload };
        case 'setDescription':
            return { ...state, description: action.payload };
        case 'setDomain':
            return { ...state, domain: action.payload };
        case 'setEhs':
            return { ...state, ehs: action.payload };
        case 'setGxp':
            return { ...state, gxp: action.payload };
        case 'setHasError':
            return { ...state, hasError: action.payload };
        case 'setFinancial':
            return { ...state, financial: action.payload };
        case 'setOpenDeleteDialogState':
            return { ...state, openDeleteDialogState: action.payload };
        case 'setOwner':
            return { ...state, owner: action.payload };
        case 'setPii':
            return { ...state, pii: action.payload };
        default:
            throw new Error(`Unhandled action type: ${actionType}`);
    }
}
