import React from 'react';
import GroupTransferList from './GroupTransferList';
import { Profile } from '../../interfaces/profile';

type Group = { name: string };
type Member = { username: string };

type DatasetGroupsProps = {
    groups: Group[];
    members: Member[];
    onAddGroup: (group: Group, profile: Profile) => void;
    onRemoveGroup: (group: Group) => void;
    onChangeProfile: (profile: Profile) => void;
    profiles: Profile[];
};

function DatasetGroups({
    groups,
    members,
    onAddGroup,
    onRemoveGroup,
    onChangeProfile,
    profiles,
}: DatasetGroupsProps) {
    return (
        <React.Fragment>
            <GroupTransferList
                columns={[
                    { title: 'Group', field: 'name', defaultSort: 'asc' },
                    { title: 'Profile', field: 'profile', hidden: false },
                ]}
                leftHeader="All groups"
                leftItems={groups}
                rightHeader="Groups"
                rightItems={members}
                onLeftChanged={({ item: group, profile }) => {
                    onAddGroup(group, profile);
                }}
                onRightChanged={({ item: member }) => {
                    onRemoveGroup(member);
                }}
                editable={true}
                onChangeProfile={onChangeProfile}
                profiles={profiles}
            />
        </React.Fragment>
    );
}

export default DatasetGroups;
