import { AuthTokens } from 'aws-amplify/auth';

const HttpMethod = {
    GET: 'GET',
    PUT: 'PUT',
    POST: 'POST',
};

function generateRequest(
    url: string,
    method: string = HttpMethod.GET,
    body: any = {},
    headers: any = {},
) {
    const defaultHeaders = { Accept: 'application/json', 'Content-Type': 'application/json' };
    const params = {
        method: method,
        headers: new Headers(Object.assign(headers, defaultHeaders)),
    };
    if (method !== HttpMethod.GET) {
        // @ts-ignore
        params['body'] = JSON.stringify(body);
    }
    return new Request(url, params);
}

async function doRequest(httpRequest: any, authSession: AuthTokens) {
    try {
        httpRequest.headers.append('Authorization', authSession.idToken?.toString());
        const response = await fetch(httpRequest);
        const responseText = await response.text();
        const responseData = responseText.length ? JSON.parse(responseText) : null;
        //console.log("RESP", response.status, responseText);
        if (response.status === 204) {
            return true;
        } else {
            return responseData;
        }
    } catch (error) {
        var startStack = new Error().stack;
        // @ts-ignore
        error.stack = error.stack + '\n' + startStack.substring(startStack.indexOf('\n') + 1);
        console.log('Fetch failed', httpRequest.url, error);
        throw error;
    }
}

export { generateRequest, doRequest };
