import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@mui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';

export async function searchADUsers(graphToken: string, inputFilter: string) {
    let filter = '';
    if (typeof inputFilter !== 'undefined' && inputFilter !== '') {
        filter = `&$filter=
        startswith(displayName,'${inputFilter}') 
        or startswith(givenName,'${inputFilter}') 
        or startswith(surname,'${inputFilter}') 
        or startswith(mail,'${inputFilter}') 
        or startswith(userPrincipalName,'${inputFilter}')`;
    }
    const url = `https://graph.microsoft.com/v1.0/users?$top=5${filter}`;
    const headers = {
        Authorization: 'Bearer ' + graphToken,
    };
    const response = await fetch(url, { headers: headers });
    const responseBody = await response.json();
    return responseBody.value;
}

interface SearchUsersProps {
    onDialogOk: (value: string) => void;
    graphToken: string;
}

function SearchUsers({ onDialogOk, graphToken }: SearchUsersProps) {
    const [open, setOpen] = React.useState(false);
    const [dialogOpen, setDialogOpen] = React.useState(false);

    const [options, setOptions] = React.useState([]);
    const [username, setUsername] = React.useState('');
    const [loading, setLoading] = React.useState(false);

    async function handleOnInputChange(event: any, value: string, reason: string) {
        switch (reason) {
            case 'input':
                if (value.length > 2) {
                    const users = await searchADUsers(graphToken, value);
                    setOptions(users);
                    setOpen(true);
                    setLoading(false);
                } else {
                    setOptions([]);
                    setOpen(false);
                    setLoading(true);
                }
                break;
            default:
                break;
        }
    }
    async function handleOnChange(event: any, newOption: any) {
        let u = '';
        if (newOption['userPrincipalName'] !== null) {
            u = newOption['userPrincipalName'];
        }
        setUsername(u);
        setDialogOpen(true);
    }

    async function handleDialogCancel() {
        setDialogOpen(false);
    }

    async function handleDialogOk() {
        setOptions([]);
        setDialogOpen(false);
        onDialogOk(username);
    }

    return (
        <div>
            <Dialog
                open={dialogOpen}
                onClose={handleDialogCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{username}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Add {username} to NNEDL
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogCancel} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDialogOk} color="primary" autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>

            <Autocomplete
                id="search-users-autocomplete"
                open={open}
                onOpen={() => {
                    options.length > 2 ? setOpen(true) : setOpen(false);
                }}
                onClose={() => {
                    setOpen(false);
                }}
                onChange={handleOnChange}
                isOptionEqualToValue={(option: any, value: any) =>
                    option.userPrincipalName === value.userPrincipalName
                }
                getOptionLabel={(option: any) => option.displayName}
                onInputChange={handleOnInputChange}
                options={options}
                loading={loading}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Active Directory users"
                        variant="outlined"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? (
                                        <CircularProgress color="inherit" size={20} />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                )}
            />
        </div>
    );
}

export default SearchUsers;
