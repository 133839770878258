import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

import { AuthTokens, fetchAuthSession, signInWithRedirect, signOut } from 'aws-amplify/auth';
import { useGlobalContext } from './GlobalContext';
import { QueryFactory } from '../Apis/Query';
import { Hub } from 'aws-amplify/utils';
import { User } from '../Home/initialState';

const authStateKey = 'authState';

export interface Identity {
    idToken: string;
    accessToken: string;
    username: string;
    steward: string;
}

interface InitialState {
    authState: any;
    signInText: string;
    identity?: Identity | null;
    graphToken: string;
    authSession: AuthTokens;
    authUser: User | null;
    login: () => void;
    logout: () => void;
}

const initialState: InitialState = {
    authState: null,
    signInText: 'Please sign in',
    identity: null,
    graphToken: '',
    authSession: {} as AuthTokens,
    authUser: null,
    login: () => {},
    logout: () => {},
};

export const AuthContext = createContext(initialState);

export const AuthProvider = ({ children }: { children: JSX.Element }) => {
    const { config, addError } = useGlobalContext();
    const [authState, setAuthState] = useState(initialState.authState);
    const [authSession, setAuthSession] = useState(initialState.authSession);
    const [signInText, setSignInText] = useState(initialState.signInText);
    const [identity, setIdentity] = useState(initialState.identity);
    const [graphToken, setGraphToken] = useState(initialState.graphToken);
    const [authUser, setAuthUser] = useState(initialState.authUser);

    const logout = useCallback(async () => {
        localStorage.clear();
        await signOut();
    }, []);

    const login = useCallback(async () => {
        await signInWithRedirect({ provider: { custom: 'CognitoAADProvider' } });
    }, []);

    const getGraphToken = useCallback(
        async (session: AuthTokens, config) => {
            try {
                if (graphToken === '') {
                    return await QueryFactory({
                        config,
                        authSession: session,
                    }).getGraphToken();
                }
            } catch (err) {
                await logout();
            }
        },
        [logout, config, graphToken],
    );

    const getAndSetUserIdentity = useCallback(
        async (session: AuthTokens, config) => {
            try {
                const username = (
                    session.idToken?.payload?.identities as Array<{ userId: string }>
                )?.[0]?.userId;
                const userData = await QueryFactory({
                    config,
                    authSession: session,
                }).getUser(username);
                return {
                    identity: {
                        idToken: session.idToken?.toString() || '',
                        accessToken: session.accessToken?.toString() || '',
                        username: username,
                        steward: userData.steward,
                    },
                    authUser: userData,
                };
            } catch (err) {
                await logout();
            }
        },
        [logout, identity],
    );

    const handleSignedInEvent = useCallback(async () => {
        setSignInText('Please wait, authorizing...');
        setAuthState('signingIn');
        try {
            const { tokens } = await fetchAuthSession();
            if (tokens) {
                const session = tokens;
                console.log('Init AuthSession', session);
                setAuthSession(session);
                const userAndIdentity = await getAndSetUserIdentity(session, config);
                const g = await getGraphToken(session, config);
                setIdentity(userAndIdentity?.identity);
                setAuthUser(userAndIdentity?.authUser);
                setGraphToken(g);
                const a = {
                    state: 'signedIn',
                    identity: userAndIdentity?.identity,
                    graphToken: g,
                    session: session,
                    authUser: userAndIdentity?.authUser,
                };
                localStorage.setItem(authStateKey, JSON.stringify(a));
                console.debug('signedIn');
                setSignInText('');
                setAuthState('signedIn');
            }
        } catch (e) {
            await logout();
        }
    }, [getAndSetUserIdentity, getGraphToken, logout, config]);

    const signedOutEventHandler = useCallback(async () => {
        setSignInText('Please wait, signing out...');
        localStorage.removeItem(authStateKey);
        setGraphToken(initialState.graphToken);
        setAuthState(initialState.authState);
        setAuthSession(initialState.authSession);
        setIdentity(initialState.identity);
        setSignInText(initialState.signInText);
        setAuthUser(initialState.authUser);
    }, []);

    useEffect(() => {
        const handleAuthEvent = async ({ payload: { event } }: any) => {
            console.log('Hub received');
            switch (event) {
                case 'signedIn':
                case 'signInWithRedirect':
                    console.log('Hub signIn event');
                    handleSignedInEvent().catch((err) => {
                        addError(err);
                        console.error(err);
                    });
                    break;
                case 'signedOut':
                    console.log('Hub signOut event');
                    signedOutEventHandler().catch((err) => {
                        addError(err);
                        console.error(err);
                    });
                    break;
                case 'signInWithRedirect_failure':
                case 'tokenRefresh_failure':
                    console.log('Sign in failure');
                    break;
                default:
                    console.debug('Unused Hub event', event);
            }
        };

        Hub.listen('auth', handleAuthEvent);
    }, [addError, handleSignedInEvent, signedOutEventHandler]);

    useEffect(() => {
        let a: any | null = null;
        try {
            a = JSON.parse(localStorage.getItem(authStateKey) || '');
        } catch {}
        if (a !== null && (a.state === 'signedIn' || a.state === 'signInWithRedirect')) {
            console.debug('signedIn');
            setIdentity(a.identity);
            setGraphToken(a.graphToken);
            setAuthUser(a.authUser);
            setSignInText('');
            handleSignedInEvent();
        }
    }, []);

    return (
        <AuthContext.Provider
            value={{
                authState,
                authSession,
                identity,
                graphToken,
                signInText,
                authUser,
                logout,
                login,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
