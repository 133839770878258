import { doRequest, generateRequest } from '../helpers';
import { AuthTokens } from 'aws-amplify/auth';

class ValidationErrors extends Error {
    public errors: string[];

    constructor(message: string, errors: string[]) {
        super(message);
        this.errors = errors;
        this.name = 'ValidationError';
    }
}

export const Actions = {
    CREATE_DATASET: 'create_ds',
    DELETE_DATASET: 'delete_ds',
    ADD_MEMBER: 'create_ds_edge',
    REMOVE_MEMBER: 'delete_ds_edge',
    ADD_STEWARD: 'create_ds_edge',
    REMOVE_STEWARD: 'delete_ds_edge',
    ADD_USER: 'create_user',
    CREATE_SUBSET: 'create_subset',
    UPDATE_LIFECYCLE: 'update_lifecycle',
    ADD_GROUP: 'attach_group_to_ds',
    REMOVE_GROUP: 'detach_group_from_ds',
    UPDATE_GROUP_PROFILE: 'update_group_profile',
    CREATE_CRAWLER: 'create_glue_crawler',
    UPDATE_CRAWLER_SCHEDULE: 'modify_crawler_schedule',
    START_CRAWLER: 'start_glue_crawler',
    STOP_CRAWLER: 'stop_glue_crawler',
    DELETE_CRAWLER: 'delete_glue_crawler',
    UPDATE_DATASET: 'update_ds',
    UPDATE_ACTIVE_DATASET: 'flip_active_on_member',
    CREATE_DS_FOLDER: 'create_ds_folder',
    DELETE_SUBSET: 'delete_subset',
    DELETE_FILE: 'delete_ds_file',
    DELETE_FOLDER: 'delete_ds_folder',
};

export interface CommandFactoryInput {
    config: {
        api_base_url: string;
    };
    authSession: AuthTokens;
}

export function CommandFactory({ config, authSession }: CommandFactoryInput) {
    const url = `${config.api_base_url}/command/`;

    async function createUser(username: string) {
        const payload = {
            action: Actions.ADD_USER,
            data: {
                username: username,
                type: 'user',
                steward: 'NA',
            },
        };
        return await doRequest(generateRequest(url, 'POST', payload), authSession);
    }

    async function createDataset(newDataset: any) {
        const payload = {
            action: Actions.CREATE_DATASET,
            data: newDataset,
        };
        return await doRequest(generateRequest(url, 'POST', payload), authSession);
    }

    async function deleteDataset(name: string) {
        const payload = {
            action: Actions.DELETE_DATASET,
            data: { ds_name: name },
        };
        return await doRequest(generateRequest(url, 'POST', payload), authSession);
    }

    async function deleteFile(name: string, key: string) {
        console.log('finally, calling the api...');
        const payload = {
            action: Actions.DELETE_FILE,
            data: {
                ds_name: name,
                key: key,
                zone: 'curated',
            },
        };
        return await doRequest(generateRequest(url, 'POST', payload), authSession);
    }

    async function deleteFolder(name: string, key: string) {
        const payload = {
            action: Actions.DELETE_FOLDER,
            data: {
                ds_name: name,
                key: key,
                zone: 'curated',
            },
        };
        return await doRequest(generateRequest(url, 'POST', payload), authSession);
    }

    async function addMember(ds_name: string, username: string, profile: string) {
        const is_machine_user = username.includes('nnedl-iamu');
        console.log('is_machine_user', is_machine_user);

        const data = {
            ds_name: ds_name,
            type: 'member',
            profile: profile,
        };
        if (is_machine_user) {
            data['machineuser' as keyof typeof data] = username;
        } else {
            data['user' as keyof typeof data] = username;
        }
        const payload = {
            action: Actions.ADD_MEMBER,
            data: data,
        };
        return await doRequest(generateRequest(url, 'POST', payload), authSession);
    }

    async function removeMember(dsName: string, username: string) {
        const is_machine_user = username.includes('nnedl-iamu');
        const data = {
            type: 'member',
            ds_name: dsName,
        };
        if (is_machine_user) {
            data['machineuser' as keyof typeof data] = username;
        } else {
            data['user' as keyof typeof data] = username;
        }
        const payload = {
            action: Actions.REMOVE_MEMBER,
            data: data,
        };
        return await doRequest(generateRequest(url, 'POST', payload), authSession);
    }

    async function addSteward(dsName: string, username: string) {
        const payload = {
            action: Actions.ADD_STEWARD,
            data: {
                ds_name: dsName,
                type: 'steward',
                steward: username,
            },
        };
        return await doRequest(generateRequest(url, 'POST', payload), authSession);
    }

    async function removeSteward(dsName: string, username: string) {
        const payload = {
            action: Actions.REMOVE_STEWARD,
            data: {
                ds_name: dsName,
                type: 'steward',
                steward: username,
            },
        };
        return await doRequest(generateRequest(url, 'POST', payload), authSession);
    }

    async function createSubset(
        dsName: string,
        subsetName: string,
        path: string,
        schedule: string,
    ) {
        const payload = {
            action: Actions.CREATE_SUBSET,
            data: {
                ds_name: dsName,
                subset_name: subsetName,
                path: path,
                schedule: schedule,
            },
        };
        return await doRequest(generateRequest(url, 'POST', payload), authSession);
    }

    async function updateLifecycle(dsName: string, lifecycle: string) {
        const payload = {
            action: Actions.UPDATE_LIFECYCLE,
            data: {
                ds_name: dsName,
                lifecycle: lifecycle,
            },
        };
        console.log('payload:', payload);
        return await doRequest(generateRequest(url, 'POST', payload), authSession);
    }

    async function addGroup(dsName: string, groupName: string, profile: string) {
        const data = {
            ds_name: dsName,
            group_name: groupName,
            type: 'member',
            profile: profile,
        };
        const payload = {
            action: Actions.ADD_GROUP,
            data: data,
        };
        return await doRequest(
            generateRequest(url, 'POST', payload, { InvocationType: 'Event' }),
            authSession,
        );
    }

    async function removeGroup(dsName: string, groupName: string) {
        const data = {
            ds_name: dsName,
            group_name: groupName,
            type: 'member',
        };
        const payload = {
            action: Actions.REMOVE_GROUP,
            data: data,
        };
        return await doRequest(
            generateRequest(url, 'POST', payload, { InvocationType: 'Event' }),
            authSession,
        );
    }

    async function updateGroupProfile(dsName: string, groupName: string, profile: string) {
        const data = {
            ds_name: dsName,
            group_name: groupName,
            profile: profile,
        };
        const payload = {
            action: Actions.UPDATE_GROUP_PROFILE,
            data: data,
        };
        return await doRequest(generateRequest(url, 'POST', payload), authSession);
    }

    async function updateCrawlerSchedule(
        crawlerName: string,
        schedule: string,
        region: string,
        dsName: string,
    ) {
        const data = {
            crawler_name: crawlerName,
            schedule: schedule,
            region: region,
            ds_name: dsName,
        };
        const payload = {
            action: Actions.UPDATE_CRAWLER_SCHEDULE,
            data: data,
        };
        return await doRequest(generateRequest(url, 'POST', payload), authSession);
    }

    async function startCrawler(crawlerName: string, region: string, dsName: string) {
        const data = {
            crawler_name: crawlerName,
            region: region,
            ds_name: dsName,
        };
        const payload = {
            action: Actions.START_CRAWLER,
            data: data,
        };
        return await doRequest(generateRequest(url, 'POST', payload), authSession);
    }

    async function stopCrawler(crawlerName: string, region: string, dsName: string) {
        const data = {
            crawler_name: crawlerName,
            region: region,
            ds_name: dsName,
        };
        const payload = {
            action: Actions.STOP_CRAWLER,
            data: data,
        };
        return await doRequest(generateRequest(url, 'POST', payload), authSession);
    }

    async function createCrawler({ ds_name, region, subset_name, schedule, path }: any) {
        const data = {
            ds_name: ds_name,
            subset_name: subset_name,
            region: region,
            schedule: schedule,
            path: path,
        };
        const payload = {
            action: Actions.CREATE_CRAWLER,
            data: data,
        };
        return await doRequest(generateRequest(url, 'POST', payload), authSession);
    }

    async function deleteCrawler(crawlerName: string, region: string, dsName: string) {
        const data = {
            crawler_name: crawlerName,
            region: region,
            ds_name: dsName,
        };
        const payload = {
            action: Actions.DELETE_CRAWLER,
            data: data,
        };
        return await doRequest(generateRequest(url, 'POST', payload), authSession);
    }

    async function updateDataset(dataset: any) {
        const data = {
            ds_name: dataset.name,
            cost_center: dataset.cost_center,
            topic_arn: dataset.topic_arn,
            domain: dataset.domain,
            description: dataset.description,
            company: dataset.company,
            owner: dataset.owner,
            criticality: dataset.criticality,
            confidentiality: dataset.confidentiality,
            pii: dataset.pii,
            gxp: dataset.gxp,
            financial: dataset.financial,
            env_health_safety: dataset.env_health_safety,
        };
        const payload = {
            action: Actions.UPDATE_DATASET,
            data: data,
        };
        return await doRequest(generateRequest(url, 'POST', payload), authSession);
    }

    async function toggleActiveDataset(dsName: string, user: string) {
        const data = {
            ds_name: dsName,
            user: user,
        };
        const payload = {
            action: Actions.UPDATE_ACTIVE_DATASET,
            data: data,
        };
        return await doRequest(generateRequest(url, 'POST', payload), authSession);
    }

    async function createDsFolder(dsName: string, prefix: string) {
        const data = {
            ds_name: dsName,
            prefix,
            zone: 'curated',
        };
        const payload = {
            action: Actions.CREATE_DS_FOLDER,
            data: data,
        };
        return await doRequest(generateRequest(url, 'POST', payload), authSession);
    }

    async function deleteSubset(dsName: string, subsetName: string) {
        const payload = {
            action: Actions.DELETE_SUBSET,
            data: {
                ds_name: dsName,
                subset_name: subsetName,
            },
        };
        return await doRequest(generateRequest(url, 'POST', payload), authSession);
    }

    return {
        createUser,
        createDataset,
        deleteDataset,
        addMember,
        removeMember,
        addSteward,
        removeSteward,
        createSubset,
        updateLifecycle,
        addGroup,
        removeGroup,
        updateGroupProfile,
        updateCrawlerSchedule,
        startCrawler,
        stopCrawler,
        createCrawler,
        deleteCrawler,
        updateDataset,
        toggleActiveDataset,
        createDsFolder,
        deleteSubset,
        deleteFile,
        deleteFolder,
    };
}
