import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import MaterialTable from 'material-table';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core';
import ChevronRight from '@mui/icons-material/ChevronRight';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Profile } from '../interfaces/profile';

const useStyles = makeStyles((theme) => ({
    displayNone: {
        display: 'none',
    },
    profileTitle: {
        fontSize: 14,
    },
}));

const permissionTable = {
    r: 'read',
    w: 'write',
    n: 'none',
};

interface TransferListProps {
    leftHeader: any;
    rightHeader: any;
    columns: any;
    leftItems: any;
    rightItems: any;
    onLeftChanged: (arg: any) => void;
    onRightChanged: (arg: any) => void;
    editable: boolean;
    onChangeProfile?: (profile: Profile) => void;
    profiles?: Profile[];
}

function TransferList({
    leftHeader,
    rightHeader,
    columns,
    leftItems,
    rightItems,
    onLeftChanged,
    onRightChanged,
    editable,
    onChangeProfile,
    profiles,
}: TransferListProps) {
    const [left, setLeft] = React.useState(leftItems);
    const [right, setRight] = React.useState(rightItems);
    const rightRef = React.useRef(right);
    const leftRef = React.useRef(left);

    const getPermission = (profileName: string) => {
        if (profiles) {
            const profile = profiles.find((x) => x.name === profileName);
            if (profile) {
                return {
                    // @ts-ignore
                    source: permissionTable[profile.permissions.charAt(0)],
                    // @ts-ignore
                    raw: permissionTable[profile.permissions.charAt(1)],
                    // @ts-ignore
                    transient: permissionTable[profile.permissions.charAt(2)],
                    // @ts-ignore
                    curated: permissionTable[profile.permissions.charAt(3)],
                };
            }
        }
        return null;
    };
    const displayPermissions = (profile: string) => {
        const p = getPermission(profile);

        return (
            <ul>
                <li>Source: {p ? p.source : ''}</li>
                <li>Raw: {p ? p.raw : ''}</li>
                <li>Transient: {p ? p.transient : ''}</li>
                <li>Curated: {p ? p.curated : ''}</li>
            </ul>
        );
    };

    const _setRight = (data: any) => {
        rightRef.current = data;
        setRight(data);
    };
    const _setLeft = (data: any) => {
        leftRef.current = data;
        setLeft(data);
    };

    useEffect(() => {
        _setLeft(leftItems);
        _setRight(rightItems);
    }, [leftItems, rightItems]);

    const handleToRight = (event: any, item: any) => {
        event.preventDefault();
        const l = left.filter((m: any) => m.tableData.id !== item.tableData.id);
        const r = [...right, item];
        _setRight(r);
        _setLeft(l);
        onLeftChanged({ item, left, right });
    };

    const handleToLeft = (event: any, item: any) => {
        event.preventDefault();
        const r = right.filter((m: any) => m.tableData.id !== item.tableData.id);
        const l = [...left, item];
        _setRight(r);
        _setLeft(l);
        onRightChanged({ item, left, right });
    };

    const handleChangeProfile = async (event: any, item: any) => {
        const newRight = rightRef.current.map((x: any) => {
            if (x.username === item.username) {
                const updatedItem = {
                    ...x,
                    profile: event.target.value,
                };
                return updatedItem;
            }
            return x;
        });
        _setRight(newRight);

        if (onChangeProfile) {
            await onChangeProfile({ username: item.username, profile: event.target.value });
        }
    };

    const classes = useStyles();

    return (
        <Grid container component="span" spacing={2}>
            <Grid container component="span" spacing={5}>
                <Grid component="span" item xs={6}>
                    <MaterialTable
                        title={leftHeader}
                        columns={columns}
                        data={left}
                        actions={[
                            {
                                icon: 'addbox',
                                tooltip: 'Add',
                                onClick: handleToRight,
                            },
                        ]}
                        options={{
                            grouping: false,
                            sorting: true,
                            actionsColumnIndex: -1,
                            // @ts-ignore
                            cellStyle: { width: '85%' },
                            actionsCellStyle: { width: '15%' },
                        }}
                    />
                </Grid>
                <Grid component="span" item xs={6}>
                    <MaterialTable
                        detailPanel={[
                            {
                                render: (rowData) => {
                                    return (
                                        <Card>
                                            <CardContent>
                                                <Typography
                                                    className={classes.profileTitle}
                                                    color="textSecondary"
                                                    gutterBottom
                                                >
                                                    To change the profile for the current user
                                                    select one of the profiles in the dropdown. The
                                                    permission for a profile defines the access
                                                    level for each dataset zones.
                                                </Typography>
                                                <div>
                                                    <Select
                                                        value={rowData.profile}
                                                        onChange={(event) =>
                                                            handleChangeProfile(event, {
                                                                profile: rowData.profile,
                                                                username: rowData.username,
                                                            })
                                                        }
                                                    >
                                                        {(profiles || []).map((x) => (
                                                            <MenuItem key={x.name} value={x.name}>
                                                                {x.name}
                                                            </MenuItem>
                                                        ))}
                                                        ;
                                                    </Select>
                                                </div>
                                                {displayPermissions(rowData.profile)}
                                            </CardContent>
                                        </Card>
                                    );
                                },
                                disabled: !editable,
                                icon: () => (
                                    <ChevronRight
                                        className={!editable ? classes.displayNone : ''}
                                    />
                                ),
                                openIcon: ExpandMore,
                            },
                        ]}
                        title={rightHeader}
                        columns={columns}
                        data={rightRef.current}
                        actions={[
                            {
                                icon: 'remove',
                                tooltip: 'Remove user',
                                onClick: handleToLeft,
                            },
                        ]}
                        options={{
                            grouping: false,
                            sorting: true,
                            actionsColumnIndex: -1,
                            // @ts-ignore
                            cellStyle: { width: '85%' },
                            actionsCellStyle: { width: '15%' },
                        }}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
}
export default TransferList;
