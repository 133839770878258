import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App/App';
import { Amplify } from 'aws-amplify';
import config from './config.json';

let baseUrl = window.location.hostname;

const loadConfig = async () => {
    if (process.env.REACT_APP_BASE_URL) {
        baseUrl = process.env.REACT_APP_BASE_URL;
    }

    const bootstrapEndpoint = `https://api.${baseUrl}/bootstrap`;
    const response = await fetch(bootstrapEndpoint);

    if (response && response.ok && response.status === 200) {
        const data = await response.json();

        applyConfig(data);
    } else {
        throw new Error('Failed to get config');
    }
};

const applyConfig = (data: any) => {
    config.env = data.env;
    config.app_url = baseUrl;
    config.api_base_url = `https://api.${baseUrl}`;
    config.identityPoolId = data.identityPoolId;
    config.userPoolId = data.userPoolId;
    config.userPoolWebClientId = data.userPoolWebClientId;
    config.cognitoDomain = data.cognitoDomain;
    config.redirectSignIn = process.env.REACT_APP_REDIRECT_SIGNIN
        ? process.env.REACT_APP_REDIRECT_SIGNIN
        : data.redirectSignIn;
    config.redirectSignOut = process.env.REACT_APP_REDIRECT_SIGNOUT
        ? process.env.REACT_APP_REDIRECT_SIGNOUT
        : data.redirectSignOut;
    config.accountId = data.accountId;
    config.featureSwitches = data.featureSwitches;
};

const start = async () => {
    try {
        await loadConfig();

        Amplify.configure({
            Auth: {
                Cognito: {
                    identityPoolId: config.identityPoolId,
                    userPoolId: config.userPoolId,
                    userPoolClientId: config.userPoolWebClientId,
                    loginWith: {
                        oauth: {
                            scopes: ['email', 'openid'],
                            domain: config.cognitoDomain,
                            redirectSignIn: [config.redirectSignIn],
                            redirectSignOut: [config.redirectSignOut],
                            responseType: 'code',
                        },
                    },
                },
            },
        });

        const container = document.getElementById('root');
        ReactDOM.render(<App config={config} />, container);
    } catch (error) {
        const container = document.getElementById('root');
        ReactDOM.render(<div>Error loading site</div>, container);
    }
};

start();
