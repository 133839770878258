import React, { createContext, useContext, useReducer, useState } from 'react';
// @ts-ignore
import { v4 } from 'uuid';
import Backdrop from './Backdrop';

interface Action {
    type: string;
    payload?: any;
}

export interface Notification {
    title: string;
    description: string;
    content: string;
    id: string;
}

interface FeatureSwitch {
    name: string;
    enabled: boolean;
}

export interface Config {
    featureSwitches: FeatureSwitch[];
    env?: string;
    region?: string;
}

interface State {
    config: Config;
    errors: Error[];
    notifications: Notification[];
    backdrop: boolean;
    addError: (error: Error) => Promise<void>;
    addNotification: (
        id: string,
        title: string,
        description: string,
        content: string,
    ) => Promise<void>;
    toggleFeature: (name: string) => Promise<void>;
    removeNotification: (id: string) => void;
    clearNotifications: () => void;
    isFeatureEnabled: (name: string) => boolean;
    setBackdrop: (val: boolean) => void;
}

function globalContextReducer(state: State, action: Action) {
    switch (action.type) {
        case 'ADD_ERROR':
            const error = action.payload;
            const notification = {
                id: v4(),
                title: error.message,
                description: error.name,
                content: error.stack,
            };
            state.notifications.push(notification);
            state.errors.push(error);
            return {
                ...state,
                errors: state.errors,
            };

        case 'ADD_NOTIFICATION':
            state.notifications.push(action.payload);
            return {
                ...state,
                notifications: state.notifications,
            };

        case 'CLEAR_NOTIFICATIONS':
            return {
                ...state,
                notifications: [],
            };

        case 'REMOVE_NOTIFICATION':
            const notifications = state.notifications.filter((n) => n.id !== action.payload);
            return {
                ...state,
                notifications: notifications,
            };

        case 'TOGGLE_FEATURE':
            const config = state.config;
            if (Array.isArray(config.featureSwitches) && config.featureSwitches.length) {
                state.config.featureSwitches = state.config.featureSwitches.map((feature) => {
                    if (feature['name'] === action.payload) {
                        feature['enabled'] = !feature['enabled'];
                    }
                    return feature;
                });
            }
            return {
                ...state,
                config: config,
            };

        default:
            return state;
    }
}

const initialState: State = {
    config: {
        featureSwitches: [],
    },
    errors: [],
    notifications: [],
    backdrop: false,
    addError: (error: Error) => Promise.resolve(),
    addNotification: (id: string, title: string, description: string, content: string) =>
        Promise.resolve(),
    toggleFeature: (name: string) => Promise.resolve(),
    removeNotification: (id: string) => {},
    clearNotifications: () => {},
    isFeatureEnabled: (name: string) => false,
    setBackdrop: (val: boolean) => {},
};

export const GlobalContext = createContext(initialState);

interface GlobalProviderProps {
    config: Config;
    children: JSX.Element;
}

export const GlobalProvider = ({ config, children }: GlobalProviderProps) => {
    initialState.config = config;

    const [state, dispatch] = useReducer(globalContextReducer, initialState);
    const [newState, setNewState] = useState(initialState);

    async function addError(error: Error) {
        dispatch({
            type: 'ADD_ERROR',
            payload: error,
        });
    }

    async function addNotification(
        id: string,
        title: string,
        description: string,
        content: string = '',
    ) {
        dispatch({
            type: 'ADD_NOTIFICATION',
            payload: {
                id: id,
                title: title,
                description: description,
                content: content,
            },
        });
    }

    async function removeNotification(id: string) {
        dispatch({
            type: 'REMOVE_NOTIFICATION',
            payload: id,
        });
    }

    async function clearNotifications() {
        dispatch({
            type: 'CLEAR_NOTIFICATIONS',
        });
    }
    async function toggleFeature(name: string) {
        dispatch({
            type: 'TOGGLE_FEATURE',
            payload: name,
        });
    }

    function isFeatureEnabled(name: string) {
        const config = state.config;
        let enabled = false;
        if (Array.isArray(config.featureSwitches) && config.featureSwitches.length) {
            const feature = config.featureSwitches.filter((f) => {
                return f['name'] === name;
            });
            enabled = feature.length > 0 && feature[0]['enabled'];
        }
        return enabled;
    }

    function setBackdrop(visible: boolean) {
        setNewState({ ...newState, backdrop: visible });
    }

    return (
        <GlobalContext.Provider
            value={{
                config,
                errors: state.errors,
                notifications: state.notifications,
                addError,
                addNotification,
                removeNotification,
                clearNotifications,
                toggleFeature,
                isFeatureEnabled,
                backdrop: newState.backdrop,
                setBackdrop,
            }}
        >
            <Backdrop />
            {children}
        </GlobalContext.Provider>
    );
};

export function useGlobalContext() {
    return useContext(GlobalContext);
}

export function useFeatureSwitch(name: string) {
    const globalContext = useContext(GlobalContext);
    const featureSwitch = globalContext.config.featureSwitches.filter((f) => f.name === name)[0];

    console.debug('Feature switch config: %O', globalContext.config.featureSwitches);

    return featureSwitch ? featureSwitch['enabled'] : false;
}
