import React from 'react';
import Grid from '@material-ui/core/Grid';
import MyDataSets from './Datasets';

function Home() {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
                <MyDataSets></MyDataSets>
            </Grid>
        </Grid>
    );
}

export default Home;
