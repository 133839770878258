interface Group {
    name: string;
    active?: number;
    managed: boolean;
    region: string;
    profile: string;
}

export interface User {
    average_entry_size: number;
    member_of?: Group[];
    policy_size: number;
    show_policy_size_limit_notification: boolean;
    steward_on?: Group[];
}

export type DataRow = {
    name: string;
    active: boolean;
    type: string;
    region: string;
    role: string;
    steward: string;
    member: string;
};

export enum SortDirection {
    ASC,
    DESC,
}

export type State = {
    openEditDialog: boolean;
    user?: User;
    page: number;
    rowsPerPage: number;
    allRows: DataRow[];
    rows: DataRow[];
    order: SortDirection;
    orderBy: string;
    anchorEl: Element | null;
    anchorText: string;
};

export const initialState: State = {
    openEditDialog: false,
    user: undefined,
    page: 0,
    rowsPerPage: 10,
    allRows: [],
    rows: [],
    order: SortDirection.ASC,
    orderBy: 'name',
    anchorEl: null,
    anchorText: '',
};
