import { Files } from '../../../interfaces/file';

export function formatBytes(bytes: number, decimals: number = 2) {
    if (bytes === 0) {
        return '0 Bytes';
    }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function formatDate(dateString: string) {
    const timestamp = Date.parse(dateString);
    const date = new Date(timestamp);

    return date.toLocaleDateString('en-US');
}

export interface TreeNode {
    name: string;
    children: TreeNode[];
}

export function arrangeIntoTree(paths: string[]) {
    let tree: TreeNode[] = [];

    for (let i = 0; i < paths.length; i++) {
        const path = paths[i];
        let currentLevel = tree;

        for (let j = 0; j < path.length; j++) {
            const part = path[j];
            const existingPath = findWhere(currentLevel, 'name', part);

            if (existingPath) {
                currentLevel = existingPath.children;
            } else {
                const newPart = {
                    name: part,
                    children: [],
                };

                currentLevel.push(newPart);
                currentLevel = newPart.children;
            }
        }
    }

    return tree;

    function findWhere(array: TreeNode[], key: keyof TreeNode, value: any) {
        let t = 0; // t is used as a counter

        while (t < array.length && array[t][key] !== value) {
            t++;
        } // find the index where the id is the as the aValue

        if (t < array.length) {
            return array[t];
        } else {
            return false;
        }
    }
}

interface TableRow {
    name: string;
    size: number;
    lastmodified: string;
}

export function mapFilesToTable(files: Files) {
    let tableRows: TableRow[] = [];

    if (files?.files) {
        files.files.forEach((file) => {
            tableRows.push({
                name: file.Key.split('/').slice(-1)[0],
                size: file.Size,
                lastmodified: file.LastModified,
            });
        });
    }

    return tableRows;
}

export function debounce(func: any, timeout = 300) {
    let timer: any;

    return (...args: any[]) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            // @ts-ignore
            func.apply(this, args);
        }, timeout);
    };
}

export function removeFirstSubstring(str: string, searchstr: string) {
    const index = str.indexOf(searchstr);

    if (index === -1) {
        return str;
    }
    return str.slice(0, index) + str.slice(index + searchstr.length);
}
