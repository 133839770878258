import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import MaterialTable from 'material-table';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core';
import ChevronRight from '@mui/icons-material/ChevronRight';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import ConfirmDialog from '../../Common/ConfirmDialog';
import ProfileDialog from '../../Common/ProfileDialog';
import { Profile } from '../../interfaces/profile';

const useStyles = makeStyles((theme) => ({
    displayNone: {
        display: 'none',
    },
    profileTitle: {
        fontSize: 14,
    },
}));

const removeGroupMessage =
    'Members in the group will be removed from your dataset. This operation can take a while to complete depending on the amount of members in the group.';
const removeGroupTitle = 'Remove group';
const addGroupMessage =
    'A group profile determines the profile given for each user in the group. The effective profile of a user is a combination of the individual user profile and the group profile.';
const addGroupTitle = 'Add group';

const permissionTable = {
    r: 'read',
    w: 'write',
    n: 'none',
};

interface TransferListProps {
    leftHeader: any;
    rightHeader: any;
    columns: any;
    leftItems: any;
    rightItems: any;
    onLeftChanged: (arg: any) => void;
    onRightChanged: (arg: any) => void;
    editable: boolean;
    onChangeProfile: (profile: Profile) => void;
    profiles: Profile[];
}

function GroupTransferList({
    leftHeader,
    rightHeader,
    columns,
    leftItems,
    rightItems,
    onLeftChanged,
    onRightChanged,
    editable,
    onChangeProfile,
    profiles,
}: TransferListProps) {
    const [left, setLeft] = React.useState(leftItems);
    const [right, setRight] = React.useState(rightItems);
    const [showRemoveGroupDialog, setShowRemoveGroupDialog] = React.useState(false);
    const [showProfileDialog, setShowProfileDialog] = React.useState(false);
    const [groupToAdd, setGroupToAdd] = React.useState(null);
    const [groupToRemove, setGroupToRemove] = React.useState(null);
    const rightRef = React.useRef(right);
    const leftRef = React.useRef(left);

    const getPermission = (profileName: string) => {
        if (profiles) {
            const profile = profiles.find((x) => x.name === profileName);

            if (profile) {
                return {
                    source: permissionTable[
                        profile.permissions!.charAt(0) as keyof typeof permissionTable
                    ],
                    raw: permissionTable[
                        profile.permissions!.charAt(1) as keyof typeof permissionTable
                    ],
                    transient:
                        permissionTable[
                            profile.permissions!.charAt(2) as keyof typeof permissionTable
                        ],
                    curated:
                        permissionTable[
                            profile.permissions!.charAt(3) as keyof typeof permissionTable
                        ],
                };
            }
        }
        return null;
    };
    const displayPermissions = (profile: string) => {
        const p = getPermission(profile);

        return (
            <ul>
                <li>Source: {p ? p.source : ''}</li>
                <li>Raw: {p ? p.raw : ''}</li>
                <li>Transient: {p ? p.transient : ''}</li>
                <li>Curated: {p ? p.curated : ''}</li>
            </ul>
        );
    };

    const _setRight = (data: any) => {
        rightRef.current = data;
        setRight(data);
    };
    const _setLeft = (data: any) => {
        leftRef.current = data;
        setLeft(data);
    };

    useEffect(() => {
        _setLeft(leftItems);
        _setRight(rightItems);
    }, [leftItems, rightItems]);

    const handleToRight = (event: any, item: any) => {
        event.preventDefault();
        setShowProfileDialog(true);
        setGroupToAdd(item);
    };

    const handleToLeft = (event: any, item: any) => {
        event.preventDefault();
        setShowRemoveGroupDialog(true);
        setGroupToRemove(item);
    };

    const handleChangeProfile = async (event: any, item: any) => {
        const newRight = rightRef.current.map((x: any) => {
            if (x.name === item.name) {
                const updatedItem = {
                    ...x,
                    profile: event.target.value,
                };
                return updatedItem;
            }
            return x;
        });

        _setRight(newRight);
        // @ts-ignore
        await onChangeProfile({ name: item.name, profile: event.target.value });
    };

    const closeRemoveGroupDialog = () => {
        setShowRemoveGroupDialog(false);
        console.log('group to remove:', groupToRemove);
        // @ts-ignore
        const r = right.filter((m: any) => m.tableData.id !== groupToRemove.tableData.id);
        const l = [...left, groupToRemove];
        _setRight(r);
        _setLeft(l);
        onRightChanged({ item: groupToRemove, left, right });
    };

    const closeProfileDialog = (profile: string) => {
        setShowProfileDialog(false);
        console.log('profile chosen: ', profile);
        // @ts-ignore
        const l = left.filter((m) => m.tableData.id !== groupToAdd.tableData.id);
        const r = [...right, groupToAdd];
        _setRight(r);
        _setLeft(l);
        onLeftChanged({ item: groupToAdd, left, right, profile });
    };

    const cancelProfileDialog = () => {
        setShowProfileDialog(false);
    };

    const cancelRemoveGroupDialog = () => {
        setShowRemoveGroupDialog(false);
    };

    const classes = useStyles();

    return (
        <Grid container component="span" spacing={2}>
            <ConfirmDialog
                id="remove-group-dialog"
                title={removeGroupTitle}
                message={removeGroupMessage}
                isOpen={showRemoveGroupDialog}
                handleClosed={closeRemoveGroupDialog}
                handleCancel={cancelRemoveGroupDialog}
            ></ConfirmDialog>
            <ProfileDialog
                id="group-profile-dialog"
                title={addGroupTitle}
                message={addGroupMessage}
                isOpen={showProfileDialog}
                handleClosed={closeProfileDialog}
                handleCancel={cancelProfileDialog}
                profiles={profiles}
            ></ProfileDialog>
            <Grid container component="span" spacing={5}>
                <Grid component="span" item xs={6}>
                    <MaterialTable
                        title={leftHeader}
                        columns={columns}
                        data={left}
                        actions={[
                            {
                                icon: 'addbox',
                                tooltip: 'Add',
                                onClick: handleToRight,
                            },
                        ]}
                        options={{
                            grouping: false,
                            sorting: true,
                            actionsColumnIndex: -1,
                            // @ts-ignore
                            cellStyle: { width: '85%' },
                            actionsCellStyle: { width: '15%' },
                        }}
                    />
                </Grid>
                <Grid component="span" item xs={6}>
                    <MaterialTable
                        detailPanel={[
                            {
                                render: (rowData) => {
                                    return (
                                        <Card>
                                            <CardContent>
                                                <Typography
                                                    className={classes.profileTitle}
                                                    color="textSecondary"
                                                    gutterBottom
                                                >
                                                    To change the profile for the current group
                                                    select one of the profiles in the dropdown. The
                                                    permission for a profile defines the access
                                                    level for each dataset zones.
                                                </Typography>
                                                <div>
                                                    <Select
                                                        value={rowData.profile}
                                                        onChange={(event) =>
                                                            handleChangeProfile(event, {
                                                                profile: rowData.profile,
                                                                name: rowData.name,
                                                            })
                                                        }
                                                    >
                                                        {profiles.map((x) => (
                                                            <MenuItem key={x.name} value={x.name}>
                                                                {x.name}
                                                            </MenuItem>
                                                        ))}
                                                        ;
                                                    </Select>
                                                </div>
                                                {displayPermissions(rowData.profile)}
                                            </CardContent>
                                        </Card>
                                    );
                                },
                                disabled: !editable,
                                icon: () => (
                                    <ChevronRight
                                        className={!editable ? classes.displayNone : ''}
                                    />
                                ),
                                openIcon: ExpandMore,
                            },
                        ]}
                        title={rightHeader}
                        columns={columns}
                        data={rightRef.current}
                        actions={[
                            {
                                icon: 'remove',
                                tooltip: 'Remove',
                                onClick: handleToLeft,
                            },
                        ]}
                        options={{
                            grouping: false,
                            sorting: true,
                            actionsColumnIndex: -1,
                            // @ts-ignore
                            cellStyle: { width: '85%' },
                            actionsCellStyle: { width: '15%' },
                        }}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
}
export default GroupTransferList;
