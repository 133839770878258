import React from 'react';
import { Dataset } from '../../../interfaces/dataset';

export type Actions =
    | { type: 'setClientAccount'; payload: string }
    | { type: 'setClientAccountErrorText'; payload: string }
    | { type: 'setClientAccountHasError'; payload: boolean }
    | { type: 'setClientSNSTopicArn'; payload: string }
    | { type: 'setClientSNSTopicArnErrorText'; payload: string }
    | { type: 'setClientSNSTopicArnHasError'; payload: boolean }
    | { type: 'setCompany'; payload: string }
    | { type: 'setConfidentiality'; payload: string }
    | { type: 'setCostCenter'; payload: string }
    | { type: 'setCostCenterError'; payload: boolean }
    | { type: 'setCostCenterErrorText'; payload: string }
    | { type: 'setCriticality'; payload: string }
    | { type: 'setDescription'; payload: string }
    | { type: 'setDomain'; payload: string }
    | { type: 'setEhs'; payload: boolean }
    | { type: 'setGxp'; payload: boolean }
    | { type: 'setHasError'; payload: boolean }
    | { type: 'setFinancial'; payload: boolean }
    | { type: 'setOpenDeleteDialogState'; payload: boolean }
    | { type: 'setOwner'; payload: string }
    | { type: 'setPii'; payload: boolean };

export function dispatchSetWholeDataset(dispatch: React.Dispatch<Actions>, dataset: Dataset) {
    dispatch({ type: 'setFinancial', payload: dataset.financial });
    dispatch({ type: 'setCriticality', payload: dataset.criticality });
    dispatch({ type: 'setConfidentiality', payload: dataset.confidentiality });
    dispatch({ type: 'setEhs', payload: dataset.env_health_safety });
    dispatch({ type: 'setGxp', payload: dataset.gxp });
    dispatch({ type: 'setPii', payload: dataset.pii });
    dispatch({ type: 'setOwner', payload: dataset.owner });
    dispatch({ type: 'setCompany', payload: dataset.company });
    dispatch({ type: 'setDomain', payload: dataset.domain });
    dispatch({ type: 'setDescription', payload: dataset.description });
    dispatch({ type: 'setClientAccount', payload: dataset.accounts[0] }); // TODO: This needs to be changed to a list, there is a story on that
    dispatch({ type: 'setClientSNSTopicArn', payload: dataset.topic_arn });
    dispatch({ type: 'setCostCenter', payload: dataset.cost_center });
}
