import { useMemo } from 'react';
import { CommandFactory } from './Command';
import { QueryFactory } from './Query';
import { AuthTokens } from 'aws-amplify/auth';

export function useCommandApi(config: any, authSession: AuthTokens) {
    return useMemo(() => CommandFactory({ config, authSession }), [authSession, config]);
}

export function useQueryApi(config: any, authSession: AuthTokens) {
    return useMemo(() => QueryFactory({ config, authSession }), [authSession, config]);
}
